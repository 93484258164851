li.list {
  font-size: 0.875rem;
  text-align: justify;
  margin-left: 1rem;
  list-style-type: circle;
  padding: 5px 0px;
  word-wrap: break-word;
}

li.numbered-list {
  font-size: 0.875rem;
  text-align: justify;
  margin-left: 1rem;
  list-style-type: circle;
  padding: 5px 0;
  list-style-type: decimal;
}

.foldable-text {
  margin-bottom: 0px !important;
}

.cds--accordion ul {
  line-height: 125%;
}

.video{
  padding-bottom: 1rem;
}