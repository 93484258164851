@use "@carbon/styles/scss/spacing";

.tile-image {
  width: 75%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.tile-title {
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  font-size: x-large !important;
}

.tile-description {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  text-align: center;
  font-size: 1rem;
}