@use "@carbon/styles/scss/spacing";

.coperia-logo {
  max-width: 100%;
  max-height: 100%;
}

.text-param {
  font-weight: bold;
}

.text-value {
  text-align: center;
}





