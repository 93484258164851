@use "layout/navbar/navbar";

.generic-content {
  margin-bottom: 1.5rem;
}

.generic-content:not(.foldable-text), .cds--btn {
  margin-top: 1.5rem;
}

.cds--btn {
  margin-top: 1.5rem !important;
}

.generic-content img {
  max-width: 100%;
}

.generic-content title {
  font-size: 30rem;
}

.tile-description {
  font-size: 1rem;
  text-align: justify;
  line-height: 142%;
}

.title-subtitle {
  font-size: 1rem;
  text-align: justify;
  line-height: 142%;
}

.cds--accordion__content {
  width: 100%;
  padding-right: 1rem !important;
  p {
    text-align: justify;
  }
}

.cds--content {
  padding: 0.5rem;
}

.cds--header~.cds--content {
  margin-top: 2rem;
}