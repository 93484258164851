li.list {
  font-size: 0.875rem;
  text-align: justify;
  margin-left: 1rem;
  list-style-type: circle;
}

.license,
.license > * {
  font-size: 0.75rem;
}
